<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div>
          <img
            width="100%"
            v-if="loading"
            :src="'./images/loader.gif'"
            class="mr-2"
            style="margin-top: -5px;"
          />
        </div>
        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right;">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Privacy Policy</h4>
          </div>
        </div>

        <div class="card-body" v-if="!loading">
          <div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter Title"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Text:"
                label-for="input-2"
              >
                <vue-editor
                  v-model="content"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </b-form-group>

              <b-button type="submit" variant="primary">Save</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "format"],
        // [{ list: "ordered" }, { list: "bullet" }],
      ],
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
      },
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
</style>
